// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anmeldungen-tsx": () => import("./../../../src/pages/anmeldungen.tsx" /* webpackChunkName: "component---src-pages-anmeldungen-tsx" */),
  "component---src-pages-cie-aha-plus-tsx": () => import("./../../../src/pages/cie_aha_plus.tsx" /* webpackChunkName: "component---src-pages-cie-aha-plus-tsx" */),
  "component---src-pages-erwachsene-tsx": () => import("./../../../src/pages/erwachsene.tsx" /* webpackChunkName: "component---src-pages-erwachsene-tsx" */),
  "component---src-pages-foerderklasse-tsx": () => import("./../../../src/pages/foerderklasse.tsx" /* webpackChunkName: "component---src-pages-foerderklasse-tsx" */),
  "component---src-pages-galerie-tsx": () => import("./../../../src/pages/galerie.tsx" /* webpackChunkName: "component---src-pages-galerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kinder-tsx": () => import("./../../../src/pages/kinder.tsx" /* webpackChunkName: "component---src-pages-kinder-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-philosophie-tsx": () => import("./../../../src/pages/philosophie.tsx" /* webpackChunkName: "component---src-pages-philosophie-tsx" */),
  "component---src-pages-preise-tsx": () => import("./../../../src/pages/preise.tsx" /* webpackChunkName: "component---src-pages-preise-tsx" */),
  "component---src-pages-stundenplan-oberwinterthur-tsx": () => import("./../../../src/pages/stundenplan_oberwinterthur.tsx" /* webpackChunkName: "component---src-pages-stundenplan-oberwinterthur-tsx" */),
  "component---src-pages-stundenplan-wiesendangen-tsx": () => import("./../../../src/pages/stundenplan_wiesendangen.tsx" /* webpackChunkName: "component---src-pages-stundenplan-wiesendangen-tsx" */),
  "component---src-pages-stundenplan-winterthur-tsx": () => import("./../../../src/pages/stundenplan_winterthur.tsx" /* webpackChunkName: "component---src-pages-stundenplan-winterthur-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-unsere-lokale-tsx": () => import("./../../../src/pages/unsere_lokale.tsx" /* webpackChunkName: "component---src-pages-unsere-lokale-tsx" */),
  "component---src-templates-kurs-template-tsx": () => import("./../../../src/templates/kursTemplate.tsx" /* webpackChunkName: "component---src-templates-kurs-template-tsx" */),
  "component---src-templates-team-template-tsx": () => import("./../../../src/templates/teamTemplate.tsx" /* webpackChunkName: "component---src-templates-team-template-tsx" */)
}

